@if (!isLoading) {
<button
  type="submit"
  (click)="requestClick()"
  [ngClass]="{
    'btn btn-primary btn-theme me-2 w-auto ms-1 p-1 ps-2 pe-2': button === 'primary',
    'action-button me-2 w-auto p-1 ps-2 pe-2': button === 'secondary'
  }">
  @if(type=='add'){
   <img src="assets/images/icons/icon-plus.svg" />
  }@else if (type=='view-details') {
   <img src="assets/images/icons/icon-view.svg" />
  }@else if (type=='download') {
   <img src="assets/images/icons/icon-download.svg" />
  }@else if (type=='edit'){
    <img src="assets/images/icons/icon-edit-pencil.svg" />
  }@else if (type=='delete'){
    <img src="assets/images/icons/icon-delete.svg" />
  }
  {{ label | translate}}
</button>
}@else {
<button class="btn btn-primary btn-theme me-2" type="button" disabled>
  <app-spinner></app-spinner>
  {{ loadingLabel | translate }}
</button>
}
